body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
}

body.dark, html.dark, .App.dark {
  background-color: #343541 !important;
}
#textareauserprompt {
  display: none;
}


.App {
  text-align: center;
  display: flex;
  background-color: #eee;
  color: #fff;
  position: absolute;
  top: 0; bottom: 0; left: 0; right:0;
  overflow: hidden;
}
#page-wrap {
  width: calc(100% - 170px);
  margin-left: 170px;
}
#page-wrap.closed {
  width: 100%;
  margin-left: 0px;
}
.topbar {
  width: 100%;
  background: #fff;
  height: 29px;
  color: #555;
  font-size: 13px;
  padding-top: 7px;
  display: block;
}

button {
  -webkit-user-select: none;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  color: #007AFB;
  font-weight: 400;
  font-size: 42px;
  padding-bottom: 10px;
  cursor: default;
}

.sidemenu {
  width: 170px;
  padding: 10px;
  background-color: teal;
  overflow: hidden;
  height: 100%;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 5px;
  text-align: left;
  transition: ease 0.2s all;
  background-color: cornflowerblue;
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
}

.side-menu-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.login-button {
  padding: 12px;
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.2s all;
  bottom: 16px;
  position: fixed;
  width: 210px;
  opacity: 0.1;
  /* display: block; */
  display: none;
}
.login-button-view {
  padding: 12px;
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.2s all;
  bottom: 16px;
  position: fixed;
  width: 210px;
  opacity: 0.1;
  /* display: block; */
}

.login-button:hover {
  background-color: rgba(255,255,255,0.1);
  cursor: pointer;
  opacity: 1;
}

.upgrade-button {
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.2s all;
  bottom: 16px;
  width: 100%;
  display: block;
  height: 31px;
  background-color: darkorange !important;
  color: #fff;
  font-size: 13px;
  margin-left: 13px;
  margin-top: 20px;
}

.upgrade-button:hover {
  opacity: 0.9;
  cursor: pointer;
  opacity: 1;
}
.logoutbutton {
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.2s all;
  bottom: 16px;
  width: 100%;
  opacity: 0.8;
  display: block;
  height: 31px;
  background-color: transparent !important;
  color: #fff;
  font-size: 13px;
  /* margin-left: 14px; */
  text-decoration: none;
}
.logoutbutton:hover {
  background-color: rgba(255,255,255,0.1);
  cursor: pointer;
  opacity: 1;
}
.loginlink {
  text-decoration: none !important;
}

.chatbox {
flex: 1;
background-color: #343541;
position: relative;
height: 100vh;
}

.scroll-wrapper {
  height: calc(100% - 109px);
  justify-content: center;
  /* height: 100vh; */
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
  justify-content: flex-start;
  border-bottom: 1px solid #C8C8C8;
}
.scroll-wrapper.dark {
  border: none !important;
}
.scroll-wrapper.big {
  border: none;
}

.scroll-start-at-top {
  flex: 1 1 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-holder {
  left: 0;
  padding-left: 24px;
  padding-right: 24px;
  position: fixed;
  right: 0;
  width: 100%;
  /* left: 70px; */
  /* overflow: hidden; */
  z-index: 4;
  position: absolute;
}
.chat-input-holder.small {
  top: auto !important;
  bottom: -15px;
}
.chat-input-holder.small.open {
  left: 86px;
}
/* .chat-input-holder .big {
  bottom: 0px;
} */

.chat-input-textarea {
  /* color: #fff;
  font-size: 14px; */
  padding-top: 18px;
  padding-left: 19px;
  /* padding-bottom: 7px; */
  border-radius: 5px;
  border-color: none;
  margin: 12px;
  outline: none;
  /* box-shadow: 0 0 8px 0 rgba(0,0,0,0.25); */
  width: calc(90% - 25px);
  position: relative !important;
  max-width: 649px;
  min-height: 28px !important;
  line-height: 1.5;
  float: left;
}
.chat-input-textarea.light  {
  background: #f9f9f9 !important;
  animation: glow2 1400ms ease-out infinite alternate !important;
  border: 1px solid #ddd;
  box-shadow: none;
}
.chat-input-textarea.mic-on  {
  border: orange 1px solid !important;
  opacity: 0.5;
}
form#form {
  width: 100%;
  max-width: 732px;
  margin: 0 auto;
  height: 60px !important;
}
#textarea {
  overflow: auto;
  margin: 0 auto;
  display: inline-block;
  font-size: 14px;
  font-weight: 100;
  padding-right: 55px;
  min-height: 61px !important;
}
#textarea::placeholder {
  color: #fff;
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  text-align: left;
  font-size: 16px;
  z-index: 8;
}
::-webkit-input-placeholder {
  color: #fff;
}
:-ms-input-placeholder {
  color: #fff;
}
::-moz-placeholder {
  color: #fff;
}
.chat-input-textarea.light {
  color: #111 !important;
}
.chat-input-textarea.light::-webkit-input-placeholder {
  color: #666 !important;
}
.chat-input-textarea.light:-ms-input-placeholder {
  color: #666 !important;
}
.chat-input-textarea.light::-moz-placeholder {
  color: #666 !important;
}

#powerby {
  width: 100%;
  bottom: 28px;
  position: absolute;
  color: #ababab;
  font-size: 11px;
  border: 0px;
  right: 18px;
  text-align: right;
}
#powerby a {
  text-decoration: none;
  color: #ababab;
  font-size: 10px;
}
textarea#textarea.important {
  background-color: oldlace !important;
  border: 1px solid #0063FA !important;
}
textarea#textarea.important::placeholder {
color: #000 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
  text-align: left;
  font-size: 13.5px;
  z-index: 1;
}

#first-chatlog {
  display: none;
  visibility: hidden;
}


.chat-message.chatgpt {
  background-color: #444654;
}

.chat-message-center {
  width: 100%;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  /* padding: 12px;
  padding-left: 24px;
  padding-right: 24px; */
  margin: 0 auto;
  min-height: 73px;
}
.chatmessage {
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-bottom: 20px !important;
}
.chatmessageradiobutton {
  margin-top: 0px !important;
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100% !important;
  margin-left: 13px !important;
  margin-right: 13px !important;
  margin-top: 15px !important;
  /* margin-top: 19px; */
 /*  display: none !important; */
}
.avatar.chatgpt {
  background: transparent;
  border-radius: 0%;
  width: 40px;
  height: 40px;
  background-image: url('./copilot.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.user {
  background-image: url('./user.png');
  background-size: contain;
  background-repeat: no-repeat;
  right: 0px;
  position: absolute;
}

.submit {
  background-image: url('./arrow.png') !important;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  border: none;
  cursor: pointer;
  width: 15px;
  height: 30px;
  right: 8%;
  position: relative;
  margin-top: 18px;
  z-index: 4;
  display: inline-block;
  bottom: 20px;
}

.message {
  /* padding-top: 18px;
  padding-left: 20px;
  padding-right: 25px;
  padding-bottom: 15px; */
  flex-grow: 1;
  white-space: pre-line;
  line-height: 1.45 !important;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: left;
  /* max-width: 860px; */
  max-width: 720px;
  margin: 0 auto;
}

select {
  width: 147px;
  background-color: cornflowerblue;
  border: none;
  height: 28px;
  margin-top: 10px;
  color: #fff;
  padding-left: 5px;
  font-size: 12.5px;
  border-radius: 2px;
}

#logocontainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  position: relative;
  margin: 0 auto;
  z-index: 2;
}
#logowrap {
  position: relative;
  top: calc(50% - 69px);
  }
.headline {
  color: #000;
  font-size: 18px;
  position: relative;
  margin: 0 auto;
  top: 0px;
  opacity: 0;
  animation: fadein 3.5s linear 1 normal forwards;
}
.headline.dark {
  color: #fff;
}
.hidden {
display: none !important;
}
.vishidden {
  visibility: hidden;
  display: inline-block;
  width: 1px;
}
@keyframes fadein{
  0%{opacity:0}
  80%{opacity:0}
  100%{opacity:0.6}
}

.modal-overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  color: #111;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: ghostwhite;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  width: 95%;
  max-width: 380px;
  height: 486px;
  padding: 30px;
  filter: drop-shadow(0 0 0.25rem #ccc);
  border: 1px solid #ddd;
  z-index: 55555;
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.26;
}

.modalinput {
  width: 313px !important;
  display: block;
  margin: 0 auto;
}

.paybutton {
  background-color: teal;
  border: none;
  height: 60px;
  width: 100%;
  border-radius: 5px;
  color: #222922;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  margin-top: 20px;
}
.modaltitle {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Prompt', sans-serif;
    color: #007AFB;
    font-weight: 400;
    font-size: 32px;
    padding-bottom: 2px;
    cursor: default;
}
.signupinput {
  color: black;
  overflow: hidden;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 10px;
  background-color: #fff;
  height: 45px;
  padding-left: 10px;
}
.signupinput::placeholder {
  color: grey;
}
#amountofstaff {
  margin-top: 25px;
  background-color: floralwhite;
}
.amountlabel {
  margin-top: 30px;
  display: block;
}

iframe {
  height: 20px !important;
}
#card-element {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 50px;
  background: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 14px;
  width: 100%;
}

/* Tiny scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 20px; 
  margin-bottom: 20px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #555; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666; 
}

/* This is called when over 6 lines */
.scrollbar::-webkit-scrollbar {
  width: 5px;
}


.themebutton {
  background-image: url('./theme.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-color: transparent;
  cursor: pointer;
  margin-top: 11px;
  opacity: 0.4;
  margin-left: 136px;
  position: fixed;
  bottom: 109px;
  z-index: 5;
}
.themebutton:hover{
  opacity: 1;
}

.themebutton2 {
  background-image: url('./theme.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 14px;
  width: 14px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;
  display: none;
}
button.handlecopy {
  color: #fff;
  height: 14px;
  width: 14px;
  font-size: 9px;
  cursor: pointer;
  border: none;
  position: relative;
  float: right;
  margin-right: 3px;
  margin-top: -9.5px;
  z-index: 5;
  background: none;
  overflow: visible;
  background-color: #000;
  padding: 0px;
  border-radius: 100%;
  visibility: hidden !important;
}
button#handlecopy {
  visibility: hidden;
  width: 1px;
  position: absolute;
}
button.handlecopy:hover {
  opacity: 0.6;
}
button.handlecopy.dark {
  color: #fff;
}
button.handleshare {
  color: #000;
  height: 13px;
  width: 13px;
  font-size: 13px;
  cursor: pointer;
  border: none;
  right: 21px;
  position: relative;
  float: right;
  margin-right: -6px;
  margin-top: -20px;
  opacity: 0.3;
  z-index: 5;
  background: none;
  border: none;
  overflow: visible;
  visibility: hidden;;
}
button.handleshare:hover {
  opacity: 0.6;
}
button.handleshare.dark {
  color: #fff;
}



/* .light.hljs {
  background-color: #f3f3f3;
  color: #000;
  font-weight: normal;
} */
.dark.hljs {
  background-color: #343541;
  color: #fff;
  font-weight: normal;
}

.chatgpt.light, .chat-message.light  {
  background-color: #f0f0f0;
  border-bottom: 1px solid #eee;
  color: #111;
}
.chat-message.light.chatgpt {  
  /* background-color: #fff; */
  background-color: #fefefe;
  border-bottom: 1px solid #eee;
  color: #111;
}
.avatar.chatgpt.light {
  background-color: transparent;
  border: none;
}
.chatgpt.dark {
  background-color: #444654;
  color: #f7f7f7;
}
.codeblocktop {
  width: 100%;
  background-color: #333;
  height: 28px;
}
.copy-button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 7px;
  float: right;
  cursor: pointer;
}
.copy-button:hover {
  text-decoration: underline;
}
.innercodeblock {
  padding: 15px;
}
pre {
  padding-bottom: 18px;
  background-color: #000;
  border-radius: 10px;
}
.JsonMessageArea {
  font-size: 11px;
}
pre.light {
  background-color: cornsilk;
  color: #000;
}
pre.dark {
  background-color: #000;
  max-width: 524px;
  
  font-size: 11px;

}
.dark .hljs-keyword {
  color: #fff;
}
.dark .hljs-title {
  color: lightskyblue;
}
.dark .hljs-title {
  color: yellow;
}
.dark .hljs-comment {
  color: darkgrey;
  opacity: 0.8;
}
.light .hljs-keyword, .hljs-type, .hljs-string {
  color: #fff !important;
  font-weight: normal !important;
}
.hljs-symbol {
  color: #fff !important;
}
.light .hljs-property {
  color: green;
}
.light .hljs-tag {
  color: royalblue;
  font-weight: 600;
}
.dark .hljs-name {
  color: skyblue;
}
.dark .hljs-name {
  color: skyblue !important;
  font-weight: normal;
}
.dark .hljs-number {
  color: darkkhaki;
}
.dark .hljs-attribute {
  color: skyblue !important;
  font-weight: normal;
}
.dark .hljs-string {
  color: yellow !important;
  font-weight: normal;
}
.dark .hljs-property {
  color: palegreen !important;
}
.dark .hljs-attr {
  color: mediumaquamarine !important;
}
.dark .hljs-tag {
  color: #fff !important;
}
.hljs-selector-class {
  color: coral;
}
.JsonMessageArea {
  color: #fff;
  white-space: break-spaces !important;
}


  /* CODE BLOCK */
code.dark {
  color: white;
}
.hljs {
  background-color: #000 !important;
  color: #fff !important;
  /* font-weight: bold; */
  padding: 28px;
  overflow-x: hidden !important;
  white-space: break-spaces !important;
}
.chatbox.light {
  background-color: #fefefe;
}
.sw-applist {
  background-color: #f7f7f7 !important;
}
.chat-wrapper {
  background-color: #eee !important;
}
.language-plaintext.hljs {
  background-color: transparent;
  line-height: 1.3;
  top: -17px;
  position: relative;
}

.searchboxouter {
  position: relative;
  /* height: 216px; */
}
/* search-box */
.search-box {
  position: relative;
  left: 50%;
  /* position: absolute;
  left: 50%;
  top: 50%; */
  transform: translate(-50%, -50%);
  margin-top: -20px;
  /* background-color: #2c3441; */
  /* height: 60px; */
  border-radius: 40px;
  width: 90%;
  max-width: 649px;
}

.search-box:hover>.icon {
  background-color: #536179 !important;
}

.iconwrapper {
  height: 100%;
  width: 52px;
  display: flex;
  float: right;
  margin-right: -9px;
  visibility: hidden !important;
}
.icon {
  visibility: visible;
  color: #EAFAF1;
  float: right;
  width: 24px;
  font-size: 12px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
  bottom: 43px;
  position: relative;
  /* border: 1px solid #888; */
  border: none;
  z-index: 4;
  padding: 12px;
  opacity: 0.95;
}
.icon:hover {
  opacity: 1;
}
.fa-paper-plane {
  color: #0063fa;
}
.icon2 {
  bottom: 100px !important;
  background-color: orange !important;
}
.icon.dark {
    background-color: #58D68D;
}

textarea {
  /* width: 0; */
  border: none;
  outline: none;
  padding: 0 10px;
  background: none;
  font-size: 1.1rem;
  transition: 0.5s ease;
  line-height: 40px;
  color: #fff;
  resize: none;
}

.logbutton {
  background-color: transparent;
  color: #fff;
  border: none;
  white-space: nowrap;
  padding-top: 9px;
  width: 126px;
  text-align: left;
  font-size: 10px;
  cursor: pointer;
  opacity: 0.9;
  border-bottom: 1px solid teal;
  padding-left: 0px;
  height: 26px;
}
.logbutton:hover {
  opacity: 1;
}
.nothanks {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  padding-top: 15px;
  border: none;
  background: transparent;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.glow textarea {
	/* border: 1px solid #fff; */
	box-shadow: 0 2px 0 #000;
	width: 100%;
  padding-right: 32px;
  border-radius: 2px;
}

.glow textarea {
	animation: glow 1400ms ease-out infinite alternate;
	/* background: #222922;
	background: linear-gradient(#2c3441, #222922); */
  background-color: #000;
	border-color: #fff;
	box-shadow: 0 0 3px rgba(0,255,0,.1), inset 0 0 3px rgba(0,255,0,.1), 0 2px 0 #000;
	outline: none;
}


@keyframes glow {
    0% {
		border-color: #fff;
		box-shadow: 0 0 3px rgba(0,255,0,.08), inset 0 0 3px rgba(0,255,0,.05), 0 2px 0 #000;
    }	
    100% {
		border-color: #58D68D;
		box-shadow: 0 0 15px rgba(0,255,0,.2), inset 0 0 10px rgba(0,255,0,.15), 0 2px 0 #000;
    }
}
@keyframes glow2 {
  0% {
  border-color: rgba(204, 204, 204, 0.349);
  box-shadow: 0 0 3px rgba(212, 212, 212, 0.08), inset 0 0 3px rgba(212, 212, 212, 0.08), 0 2px 0 #ccc;
  }	
  100% {
  border-color: #ccc;
  box-shadow: 0 0 15px rgba(0, 97, 243, 0.1), inset 0 0 10px rgba(0, 97, 243, 0.1), 0 2px 0 #ccc;
  }
}

.loaderwrapper {
  width: 45px !important;
  height: 55px;
  width: 100% !important;
  text-align: left;
}
.loader {
  width: 33px;
  height: 33px;
  display: inline-block;
  margin: 0 auto;
  background-image: url(https://openapps.ai/img/circle.png);
  background-repeat: no-repeat;
  animation: spinit 1s linear infinite;
  background-size: 33px;
}

@keyframes spinit {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mic {
  display: block;
  background: transparent;
  border: none;
  bottom: -46px;
  position: absolute;
  cursor: pointer;
  right: 12px;
  opacity: 0.75;
  font-size: 20px;
  border-radius: 100%;
}
.mic:hover {
  opacity: 1;
}
.mic-on .fa-microphone {
  color: red;
  animation: blinker 3s linear infinite;
}
.mic-off {
  color: #AAA;
}
.fa-clipboard {
  margin: 0px;
  padding: 0px;
  margin-top: -4.84px;
  position: absolute;
  margin-left: -3.5px;
}

@keyframes blinker {  
  50% { opacity: 0.5; }
}


#holdnotice{
  top: auto !important;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  background-color: black;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  opacity: 1;
  width: 84px;
  z-index: 5;
  padding: 4px;
  border: none;
  display: none;
  animation: hideAnimation 0s ease-in 5s;
  animation-fill-mode: forwards;
}
#holdnotice:after {
  content: " ";
  position: absolute;
  right: 32px;
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid black;
}
@keyframes hideAnimation {
  to {
    display: none !important;
    visibility: hidden;
  }
}
#copynotice {
  top: 20px !important;
  left: calc(50% + 85px);
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  background-color: black;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  opacity: 1;
  width: 84px;
  z-index: 7;
  padding: 4px;
  border: none;
  display: none;
}
#sharenotice {
  top: 40px !important;
  left: calc(50% + 85px);
  animation-name: fadeOut;
  background-color: black;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  opacity: 1;
  width: 248px;
  z-index: 7;
  padding: 4px;
  border: none;
  visibility: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 38px;
}
#sharenotice a {
  color: #fff;
}
#shareappnotice {
  top: 107px !important;
  left: calc(50%);
  animation-name: fadeOut;
  background-color: ghostwhite;
  border-radius: 5px;
  color: #333;
  font-size: 14px;
  opacity: 1;
  width: 289px;
  z-index: 7;
  padding: 4px;
  border: none;
  visibility: hidden;
  padding-top: 10px;
  padding-bottom: 16px;
  margin-top: 38px;
}
#tipnotice {
  top: 160px !important;
  left: calc(50%);
  animation-name: fadeOut;
  background-color: ghostwhite;
  border-radius: 5px;
  color: #444;
  font-size: 14px;
  opacity: 1;
  width: 297px;
  z-index: 7;
  padding: 4px;
  border: none;
  visibility: hidden;
  padding: 20px;
  margin-top: 38px;
}
#shareappnotice a {
  color: #fff;
}
#promptnotice {
  top: 20px !important;
  left: calc(50% + 85px);
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  background-color: black;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  opacity: 1;
  width: 210px;
  z-index: 5;
  padding: 4px;
  border: none;
  display: none;
  margin-top: 9px;
}
#hidebutton {
  cursor: pointer;
  margin-top: 17px;
  background: black;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
.topbartext {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
label {
  font-size: 12px;
}
.PromptSelect {
  margin-top: 20px;
}

#formWizard {
  position: initial;
  text-align: center;
  margin: 0 auto;
  width: calc(100% - 170px);
  /* max-width: 540px; */
  /* height: 328px; */
  margin-top: 28px;
  top: 0px;
  z-index: 2;
  /* padding: 20px; */
  margin: 0 auto;
  color: #111;
  margin-bottom: 0px;
  margin-top: 20px;
}
#wizardtextarea{
  background-color: #fff;
  color: #000;
  width: 100%;
  max-width: 590px;
  border-radius: 10px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  height: 158px;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  display: block;
}
#wizardtextarea::placeholder {
  color: #343541;
  font-style: italic;
  font-size: 13px;
  line-height: 1.8;
}
#wizardtextarea[disabled] {
  background-color: #e0e0e0;
  color: #9e9e9e;
}
.creatorsubmit {
  margin: 0 auto;
  display: block;
  background-color: #0063FA;
  border: none;
  color: #fff;
  height: 74px;
  border-radius: 5px;
  padding: 10px;
  line-height: 20px;
  margin-top: 47px;
  width: 276px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-bottom: 53px;
}
.creatorsubmit:hover {
  opacity: 0.8;
}
/* .creatorsubmit[disabled] {
  display: none;
} */
#resetwizard {
  color: #000;
  cursor: pointer;
  display: none;
  margin-top: -12px !important;
  vertical-align: middle;
}
#resetwizard[disabled]{
  display: inline-block;
}
.chat-input-holder[disabled]{
  display: inline-block !important;
}
.sidelink {
  text-decoration: none;
  color: #007AFB;
  cursor: pointer;
}
 .sidelinktwo {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  margin-top: 18px;
  display: block;
}
.sidelinktwo:hover {
  text-decoration: underline;;
}

.app {
  background: #fff;
  height: 26px;
  width: 27px;
  padding: 4px;
  margin: 5px;
  border-radius: 7px;
  margin-top: 8px;
  margin-bottom: 6px;
  border: 1px solid rgba(231, 244, 253, 0.04);
  font-size: 15px;
  display: inline-block;
  vertical-align: bottom;
}
.app:hover {
  opacity: 0.8;
}
.wozsbluebox {
  border: 1px solid rgba(231, 244, 253, 0.12);
  border-radius: 9px;
  margin-top: 8px;
  margin-bottom: 20px;
  background-color: rgba(231, 244, 253, 0.19);
  color: #fff;
  padding-bottom: 15px;
}
.stayhungry {
  padding-top: 15px;
}
.swpostcreator.small {
  flex-direction:column-reverse !important;
  padding-bottom: 132px;
}
 .sw-applist {
  flex-direction:column !important;
} 
.swpostcreator.big {
  border-bottom: none;
  flex-direction: column-reverse !important;
}
.socialouter {
  text-align: center;
  padding-top: 25px;
  margin-bottom: -19px;
}
.socialouter a {
  color: #1a94da;
  text-decoration: none;
  font-family: system-ui;
  font-weight: 600;
}
.socialicon {
  width: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.apphidden {
  display: none;
}
.bluebox {
  width: 100%;
  overflow-y: scroll;
  margin-top: 39px;
}
.blueboxicon {
  font-size: 34px;
  padding-left: 20px;
  padding-right: 20px;
  color: #CCCCCC;
  display: inline-block;
}
.blueboxicon:hover {
  color: #0063FA;
  cursor: pointer;
}
.blueboxselected {
  color: #0063FA;
}
hr {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 14px;
  padding-bottom: 13px;
  border-bottom: none;
  visibility: visible;
}
.developersdevelopersdevelopers{
  width: 100%;
  max-width: 500px;
  padding-bottom: 5px;
  margin: 0 auto;
  text-align: center;
  color: #cbcbcb;
  font-size: 13px;
  margin-top: 20px;
}
.build {
  color: #cbcbcb;
  text-decoration: none;
}
.extensionbutton img {
  width: 218px;
  height: auto;
  float: right;
  animation: extensionfadein 1s forwards;
  animation-delay: 3s;
  opacity: 0;
  }
  .M6800 {
    overflow-x: scroll;
    white-space: nowrap;
    height: 62px;
    max-width: 360px;
    direction: rtl;
    margin: 0 auto;
  }

  .M6800::-webkit-scrollbar {
    height: 2px;
    background: #dcdcdc;
  }
  .M6800::-webkit-scrollbar-thumb {
    /* background: #ddd;  */
    background: #ccc;
  }
  .M6800::-webkit-scrollbar-thumb:hover {
    background: #a2a2a2; 
  }
  .lotus123 {
    background-color: #fff;
    border-radius: 8px;
    width: 85px;
    height: 70px;
    font-size: 26px;
    color: #0063FA;
    display: inline-block;
    float: left;
    margin: 4px;
    margin-bottom: 7px
}
.lotus123:hover {
  opacity: 0.8;
}
  .appwrapper {
    width: 90px;
    height: 109px;
    text-align: center;
    display: inline-table;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 11px;
    padding: 6px;
}

.appwrapper:hover {
  /* text-decoration: underline !important; */
}
a .appwrapper {
  color: #222;
}
.appcategorylabel {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 10px;
  color: #007AFB;
  font-size: 20px;
}
.applist {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

}
.sw-full {
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: scroll;
}
.appsfwizard {
  height: 100% !important;
  flex-direction: column-reverse;
  padding-bottom: 100px;
}
.models {
  visibility: hidden;
}

  
  @keyframes extensionfadein {
  from { opacity: 0; }
  to { opacity: 1; }
  }
.build:hover {
  color: #0063FA;
}

@media only screen and (min-width: 705px) {
  .bm-overlay {
    display: none !important;
    transition: opacity 0.3s ease 0s !important;
    transform: none !important;
  }
  /* .bm-menu-wrap {
    transform: none !important;
    display: block;
  } */
  /* .bm-burger-button {
    display: none;
  } */
  /* .topbar {
    display: none;
  } */
  .mic2 {
    display: none;
  }
  .chat-input-holder {
    /* bottom: -55px;
    width: calc(100% - 18px);
    float: right;
    position: relative; */
    top: 50%;
    margin-top: -142px;
  }
  .chat-input-holder.big.open {
    left: calc(85px);
  }
  .scroll-wrapper {
    height: calc(100% - 82px);
  }
  .gpt-inside {
    padding-left: 0px !important;
  }
  /* .creatorname {
    display: inline !important;
    text-align: left !important;
    width: 200px !important;
}
.appname {
  height: 65px !important;
}
.creatorname {
  margin-top: -28px !important;
  position: absolute;
}
.underthetiffanylamp {
  margin-left: 13px;
}
.appnamemargin {
  margin-left: 1px;
}
.appcreatorouter {
  max-width: 769px;
  margin: 0 auto;
  min-width: 449px;
}
#appdisplayname {
  max-width: 350px;
  height: 48px !important;
  overflow: visible;
  white-space: nowrap;
}
.column {
  display: inline-block;
}
.column1 {
  width: 79px;
}
.column2  {
  width: auto;
  min-width: 289px;
  text-align: left;
  overflow: visible;
  display: inline-block;
} */
}

@media only screen and (max-width: 870px) {
  .chat-input-textarea {
    width: 60%;
  }
}

@media only screen and (max-width: 705px) {

  .iconwrapper{
    width: 100%;
    position: absolute;
    margin: 0 auto;
    text-align: center;
  }
  .icon {
    height: 22px;
    right: 10px;
    position: absolute;
    width: 22px;
    top: 21px;
    /* display: none; */
  }
  .scroll-wrapper {
    max-height: calc(100% - 115px);
  }
  .scroll-wrapper.big {
    border: none;
    max-height: 100%;
    height: 100%;
  }
  #textarea {
    padding-right: 37px;
  }
  .chat-input-holder {
    left: 0px;
    /* height: 60vh; */
    padding-left: 0px;
    padding-right: 0px;
    transition: height 1s ease-in-out;
  }
  .chat-input-holder.small {
    height: 11.5vh;
    bottom: -11px;
    position: relative;
  }
  .chat-input-holder.big{
    top: 50% !important;
    margin-top: -142px;
  }
  .chat-input-textarea {
    width: calc(86%);
    padding-left: 18px;
  }
  .chat-input-textarea.small {
    /* padding-left: 37px !important */
  }
  .sidemenu {
    display: none;
  }
  .side-menu-button {
    font-size: 13px;
    text-align: center;
}
  .themebutton2 {
    position: inherit;
    display: block;
    z-index: 4;
    float: right;
    margin-right: 12px;
    margin-top: 7px;
  }
  .submit {
    opacity: 0.8;
    background-size: 12px;
    position: absolute;
  }
  .message {
    /* padding-left: 10px; */
    /* padding-right: 5px; */
  }
  .chat-message-center{
    /* padding-left: 20px;
    padding-right: 20px; */
  }
  .avatar.chatgpt {
    height: 33px !important;
    width: 33px  !important;
  }
  .avatar.user {
    height: 23px !important;
    width: 23px  !important;
  }
  .avatar {
    min-width: 35px !important;
    margin-top: 21px !important;
  }
  .gptinnermsg {
    padding-left: 10px !important;
  }
  .search-box {
    /* position: absolute; */
    left: 50%;
    margin-top: 0px;
    /* background-color: #2c3441; */
    /* height: 60px; */
    border-radius: 40px;
    transition: transform 2s ease-in-out;
    
  }
  button#mic.big {
    display: none;
}
  .mic2 {
    /* display: inline-block; */
    display: none;
    height: 94px;
    width: 94px;
    top: 31px;
    position: relative;
    margin: 0 auto;
    font-size: 30px;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    border: 1px solid crimson;
    background-color: darkred;
    animation: blinker 3s linear infinite;
    -moz-animation: audio1 1.5s infinite ease-in-out;
    -o-animation: audio1 1.5s infinite ease-in-out;
    -webkit-animation: audio1 1.5s infinite ease-in-out;
    animation: audio1 1.5s infinite ease-in-out;
  }
  .mic2-off {
    animation: none;
    background-color: #d3d3d3;
    border: 1px solid #a9a9a9;
    height: 88px;
    opacity: 0.6;
    width: 88px;
  }
  .mic2-off:hover {
    opacity: 1;
    height: 94px;
    width: 94px;
    margin-top: -4px;
  }
  .mic {
    display: none;
  }
  #page-wrap {
    width: 100%;
    margin-left: 0px
  }
  #sharenotice, #copynotice, #shareappnotice {
    left: 50%;
  }
  .promptholder.big {
    display: none;
  }
  .promptholder.small {
    width: 100%;
    display: flex;
  }
  #formWizard {
    width: 100%;
    left: 0px;
    top: 20px;
  }
.formelementscreatormode {
  width: 100%;
}
.circle {
  margin-right: -40px;
}
h1 {
  font-size: 38px;
}
.blueboxicon {
  font-size: 28px;
  padding-left: 12px;
  padding-right: 12px;
}
hr {
  border: none;
  border-top: 1px solid #cfcfcf;
  margin-top: 25px;
  padding-bottom: 5px;
  width: 100%;
  max-width: 260px;
  opacity: 0.4;
  visibility: hidden;
}
.extensionbutton {
  display: none;
}
.swfull.open {
  width: 100% !important;
}
.M6800 {
  width: 280px;
}
.xeroxparc1979-wrapper {
  padding: 0px !important;
  margin: 0 auto !important;
  margin-top: 13px !important;
}
.xeroxwrapperwrapped {
  display: block;
  width: 100%;
}
#appdisplayname {
  margin-top: 46px;
  margin-bottom: -5px !important;
}
.underthetiffanylamp {
text-align: center !important
}
.appcreatorheadingwrap {
  margin-bottom: 20px;
}
.appnamemargin {
  margin-bottom: -52px !important;
}
.appcreatormenu {
  position: relative !important;
  margin: 0 auto !important;
  margin-top: -28px !important;
}
.paytable {
  height: 400px !important;
}
.thepaycolumn {
  width: 100% !important;
  display: block !important;
}
.freeoption, .denttheuniverse {
  margin: 0px;
}
.footer a {
  font-size: 12px !important;
  right: 0px !important;
  max-width: 136px !important;
}
.dontlose{
  margin-left: 7px !important;
  margin-right: -4px !important;
}


@keyframes audio1 {
  0%,
  100% {
     box-shadow: 0 0 0 0.4em rgba(252, 37, 37, 0.4);
     border-radius: 100%;
  }
  25% {
     box-shadow: 0 0 0 0.15em rgba(252, 37, 375, 0.15);
     border-radius: 100%;
  }
  50% {
     box-shadow: 0 0 0 0.55em rgba(252, 37, 37, 0.55);
     border-radius: 100%;
  }
  75% {
     box-shadow: 0 0 0 0.25em rgba(252, 37, 37, 0.25);
     border-radius: 100%;
  }
}
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
}










/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 18px;
  height: 15px;
  left: 18px;
  top: 7px;
  opacity: 0.3;
}
.bm-burger-button:hover {
  opacity: 1;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ababab;
}

/* Color/shape of burger icon bars on hover*/
/* .bm-burger-bars-hover {
  background:dodgerblue; 
} */

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  z-index: 7 !important;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 7 !important;
}



.circle {
  background: url(https://openapps.ai/share-icon.png), #000;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  height: 18px;
  margin-top: 21px;
  width: 18px;
  margin-right: 19.5px;
  opacity: 0.3;
  z-index: 99;
  position: relative;
  opacity: 0.14;
}
.circle:hover {
  opacity: 1;
}
.radio-button:hover {
  cursor: pointer;
}
.chkhidden {
  opacity: 0 !important;
  z-index: 1;
}
.chkvisible {
  opacity: 1;
}
.radio-button {
  padding: 0px;
  margin: 0px;
  height: 16px;
  width: 18px;
  position: absolute;
  margin-top: 1px;
  /* accent-color: #fff; */
  accent-color: black;
  clip-path: circle(66% at 50% 50%);
}
.chat-message.light.chatgpt.checked{
  background-color: #BDE4FF;
  box-shadow: 20px 10px 20px 2px #ccc;
  border: dashed 3px #0063FA;
  box-sizing: border-box;
}
.chat-message.chatgpt.checked, .chat-message.checked{
  background-color: #BDE4FF;
  box-shadow: 20px 10px 20px 2px #ccc;
  border: dashed 3px #0063FA;
  box-sizing: border-box;
}


.sidemenuappear {
  animation: typing 1.2s steps(20, end) 2;
}
@keyframes typing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 100%;
  }
  80% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

.promptholder{
  flex-flow: row nowrap;
  white-space: nowrap;
  position: absolute;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  margin: 0px;
  margin-top: -28px;
  height: 27px;
  overflow: scroll;
  /* display: -webkit-box; */
  width: calc(100% - 50px);
  margin-bottom: 4px;
  text-align: left;
}
/* This is called when over 6 lines */
.promptholder::-webkit-scrollbar {
  height: 4px;
}
/* Handle */
.promptholder::-webkit-scrollbar-thumb {
  background: #a6a6a6; 
}
/* Handle on hover */
.promptholder::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2; 
}
.promptholder.big {
  display: none;
}


.prompt {
  position: relative;
  padding: 3px;
  margin: 0px;
  margin-right: 5px;
  left: 0px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  font-size: 10px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ccc;
  font-style: italic;
  opacity: 0.7;
  cursor: pointer;
  display: inline-block;
  height: 90%;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  flex: 1 1 auto;
}
.prompt:hover{
  opacity: 1;
  border: 1px solid #333;
}
.promptreveal1 {
  opacity: 0;
  animation: reveal1 2s forwards;
}
@keyframes reveal1 {
  from { opacity: 0; }
  to { opacity: 1; }
}
.promptreveal2 {
  opacity: 0;
  animation: reveal2 2s 0.5s forwards;
}
@keyframes reveal2 {
  from { opacity: 0; }
  to { opacity: 1; }
}
.promptreveal3 {
  opacity: 0;
  animation: reveal3 2s 1s forwards;
}
@keyframes reveal3 {
  from { opacity: 0; }
  to { opacity: 0.7; }
}

.promptreveal1begin {
  opacity: 1;
  animation: blinker1 1s linear infinite;
}
@keyframes blinker1 {
  50% {
    opacity: 0;
  }
}
.promptreveal2begin {
  opacity: 1;
  animation: blinker2 1s linear infinite;
}
@keyframes blinker2 {
  50% {
    opacity: 0;
  }
}
.promptreveal3begin {
  opacity: 1;
  animation: blinker3 1s linear infinite;
}
@keyframes blinker3 {
  50% {
    opacity: 0;
  }
}

#prompt-off-button {
  border: none;
  cursor: pointer;
  color: #AAA;
  opacity: 0.8;
  background-color: transparent;
}
#prompt-off-button:hover {
  opacity: 1;
}
.optiongroup {
  position: absolute;
  bottom: 20px;
  padding: 0px;
  width: 150px;
}
.sharelogo {
  width: 24px;
  margin: 4px;
  cursor: pointer;
  background-color: #0063FA;
  border-radius: 6px;
}
.sharelogo2 {
  width: 24px;
  /* margin: 6px; */
  cursor: pointer;
  background-color: #0063FA;
  border-radius: 6px;
  vertical-align: bottom;
  margin-left: 6px;
}
#sharelinks {
  padding-top: 5px;
}
#insidemessage {
  margin: 0 auto;
  max-width: 720px;
  /* padding-top: 25px; */
  text-align: left;
  padding-right: 14px;
  padding-bottom: 19px;
  line-height: 1.45 !important;
}
#insidemessage table {
  border: 1px solid #ccc;
  width: 95%;
}
#insidemessage thead {
  background-color: #eee;
  color: #000;
}
#insidemessage td {
  border-right: 1px solid #000;
  padding: 5px;
  background-color: #fefefe;
}
.livesliderbuilder  {
  margin-top: 0px;
  margin-bottom: -5px;
}
.yellowtxt {
  color: yellow;
}
.nothidden {
  display: block !important;
}
.justappcreatorhidden {
  display: none !important;
}
.gptinnermsg {
  padding-left: 20px;
}
[hidden], template {
  display: block !important;
}
.sideclock2 {
  margin-left: -16px;
  background: #007AFB;
  border-radius: 100%;
  font-size: 14px;
  padding: 2px;
  margin-bottom: -1px;
  color: #fff;
}
.gpt-inside {
  padding-bottom: 10px;;
}
.trash {
  color: white !important;
  font-size: 12px;
  margin-left: -8px;
  opacity: 0.1;
  position: relative;
}
.trash:hover {
  opacity: 1;
}
#stopButton {
  margin-top: -62px;
  position: absolute;
  padding: 5px;
  left: calc(50% - 25px);
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 26px;
  font-size: 12px;
  display: none;
  border: 1px solid #ccc;
  opacity: 0.85;
  z-index: 9;
  cursor: pointer;
}
.phreaker {
  max-height: 200px;
  overflow-y: scroll;
}
.agentNameDiv {
  text-align: left;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  padding-right: 4px;
  vertical-align: middle;
  cursor: pointer;
}
.scheduledMessageDivouter {
  margin-left: 12px;
  font-size: 8px;
  height: 17px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 98px;
  display: block;
  vertical-align: bottom;
  border-bottom: 1px solid #6495ed;
  opacity: .9;
  height: 17px;
  margin-top: 9px;
}
.sb2 {
  margin-bottom: 14px;
}